import SmoothLink from "../components/smoothlink";
import React from "react";

export type LinkProps = LinkPropsPage | LinkPropsExternal | LinkPropsAnchor;

export interface LinkPropsCommon extends React.HTMLProps<HTMLAnchorElement> {
  openInNewTab: boolean;
}

export interface LinkPropsPage extends LinkPropsCommon {
  type: "page";
  page: { Slug: string };
  anchor?: string;
}

export interface LinkPropsExternal extends LinkPropsCommon {
  type: "external";
  external: string;
}

export interface LinkPropsAnchor extends LinkPropsCommon {
  type: "anchor";
  anchor: string;
}

export function Link(props: LinkProps): React.ReactElement {
  switch (props.type) {
    case "external": {
      const { external } = props;
      return props.openInNewTab ? (
        <a {...props} href={external} target={"_blank"} rel="noreferrer">
          {props.children}
        </a>
      ) : (
        <a {...props} href={external}>
          {props.children}
        </a>
      );
    }

    case "page": {
      const { page, anchor } = props;
      const actualAnchor = anchor ? `#${anchor}` : "";
      return props.openInNewTab ? (
        <a
          {...props}
          href={`/${page.Slug}${actualAnchor}`}
          target={"_blank"}
          rel="noreferrer"
        >
          {props.children}
        </a>
      ) : (
        <a {...props} href={`/${page.Slug}${actualAnchor}`}>
          {props.children}
        </a>
      );
    }

    case "anchor": {
      const { anchor } = props;
      return props.openInNewTab ? (
        <a {...props} href={`#${anchor}`} target={"_blank"} rel="noreferrer">
          {props.children}
        </a>
      ) : (
        <SmoothLink to={anchor} {...(props as unknown)}>
          {props.children}
        </SmoothLink>
      );
    }

    default:
      return <b>Invalid link type!</b>;
  }
}
