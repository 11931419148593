import { transition, transitionFast } from "../components/util/transition";
import * as classes from "../css/methods-physiolymp.module.css";
import React, { useState } from "react";
import { useInView } from "react-intersection-observer";
import ReactMarkdown from "react-markdown";
import { useMediaQuery } from "react-responsive";
import rehypeRaw from "rehype-raw";

interface Tab {
  title: string;
  content: string;
  background: {
    formats: {
      small: { url: string };
      medium: { url: string };
    };
    url: string;
  };
}

export interface MethodsPhysiolympProps {
  subtitle: string;
  title: string;
  pages: Tab[];
}

export default function MethodsPhysiolymp(
  props: MethodsPhysiolympProps
): React.ReactElement {
  const isMobile = useMediaQuery({ maxWidth: 1023 });
  const isDesktop = useMediaQuery({ minWidth: 1024 });
  const [tabState, setTabState] = useState(-1);

  const { ref, inView } = useInView({
    threshold: 0,
    rootMargin: "300px 0px -300px 0px",
    initialInView: true,
  });

  const tabs: Tab[] = props.pages;

  const backgroundUrl = (format: null | "small" | "medium") => {
    const mediaBase = process.env.GATSBY_STRAPI_MEDIA_URL;
    const bg = tabs[Math.max(tabState, 0)].background;

    if (format === null) {
      return `url("${mediaBase}${bg.url}")`;
    }

    return `url("${mediaBase}${bg.formats?.[format]?.url ?? bg.url}")`;
  };

  return (
    <section
      className="bg-yellow-400 overflow-hidden"
      style={{
        marginTop: "8rem",
        transform: "skewY(-7.85deg)",
      }}
      ref={ref}
    >
      <div
        className="overflow-hidden"
        style={{
          marginTop: "2.7rem",
          transform: "skewY(2.5deg)",
        }}
      >
        <div
          className={classes.backdrop}
          style={{
            ["--image-xs" as never]: backgroundUrl("small"),
            ["--image-sm" as never]: backgroundUrl("small"),
            ["--image-md" as never]: backgroundUrl("medium"),
            ["--image-lg" as never]: backgroundUrl("medium"),
            ["--image-xl" as never]: backgroundUrl(null),
            ["--image-xxl" as never]: backgroundUrl(null),
          }}
        >
          <span
            id="behandlung"
            style={{
              position: "absolute",
              top: "7rem",
            }}
          />
          <div className={classes.titleContainer}>
            <div
              className={classes.subtitle}
              style={{
                transition: transition(inView, 0),
                transform: `translateY(${inView ? "0" : "-1rem"})`,
                opacity: inView ? 1 : 0,
              }}
              data-test="behandlung-subtitle"
              onClick={() => setTabState(-1)}
            >
              {props.subtitle}
            </div>
            <h2
              className={classes.title}
              style={{
                transition: transition(inView, 100),
                transform: `translateY(${inView ? "0" : "-1rem"})`,
                opacity: inView ? 1 : 0,
              }}
              data-test="behandlung-title"
            >
              <ReactMarkdown rehypePlugins={[rehypeRaw]}>
                {props.title}
              </ReactMarkdown>
            </h2>
          </div>

          {isMobile && tabState === -1 && (
            <div
              className="flex flex-col gap-3 p-4 max-w-xs relative mx-auto mt-8"
              id="mobile-tabs"
            >
              {tabs.map((tab, idx) => (
                <button
                  className="p-3 flex items-center justify-center text-center border border-gray-100 text-gray-100 text-opacity-80 font-light border-opacity-80 hover:bg-gray-100 hover:text-black"
                  key={idx}
                  onClick={() => setTabState(idx)}
                >
                  {tab.title}
                </button>
              ))}
            </div>
          )}

          {((tabState !== -1 && isMobile) || isDesktop) && (
            <div className={classes.tabContainer} id="desktop-tabs">
              <div className={classes.tabs}>
                {tabs.map((tab, idx) => (
                  <NavigationTab
                    key={idx}
                    total={tabs.length}
                    tab={tab}
                    state={[tabState, setTabState]}
                    index={idx}
                    inView={inView}
                  />
                ))}
              </div>
            </div>
          )}
        </div>
      </div>
    </section>
  );
}

function NavigationTab(props: {
  total: number;
  tab: Tab;
  index: number;
  state: [number, (value: number) => void];
  inView: boolean;
}): React.ReactElement {
  const [activeTab, setActiveTab] = props.state;
  const isActive = props.index === Math.max(activeTab, 0);
  const isInFirstHalf = props.index < props.total / 2;
  const { inView } = props;
  const isMobile = useMediaQuery({ maxWidth: 1023 });
  const isDesktop = useMediaQuery({ minWidth: 1024 });

  return (
    <div
      className={`${classes.navigationTab}${
        isActive ? ` ${classes.active}` : ""
      }`}
      onClick={() => {
        if (isDesktop) setActiveTab(props.index);
        if (isMobile) setActiveTab((props.index + 1) % props.total);
      }}
      style={{
        transition: transition(inView, 200 + (props.total - props.index) * 100),
        opacity: inView ? 1 : 0,
      }}
    >
      <div className="relative h-full">
        <div className={classes.content}>{props.tab.title}</div>
        <h3 className={classes.placeholder} aria-hidden="true">
          {props.tab.title}
        </h3>{" "}
        <svg
          height="50"
          width="50"
          viewBox="0 0 50 50"
          className={classes.tabTriangle}
        >
          <polygon
            points="0,0 10,0 50,25 10,50 0,50"
            style={{
              fill: "currentColor",
            }}
          />
        </svg>
        <svg
          height="50"
          width="50"
          viewBox="0 0 50 50"
          className={classes.tabTriangleNextIndicator}
        >
          <path
            d="M 0 4 L 36 25 L 0,46"
            style={{
              fill: "transparent",
              stroke: "currentColor",
              strokeWidth: "5",
            }}
          />
        </svg>
      </div>
      <div
        className={`${classes.text} ${
          isInFirstHalf ? classes.left : classes.right
        }`}
        style={{
          transition: transitionFast(inView && isActive, 250),
          transform: `translateY(${inView && isActive ? "0" : "-1rem"})`,
          opacity: inView && isActive ? 1 : 0,
        }}
      >
        <ReactMarkdown rehypePlugins={[rehypeRaw]}>
          {props.tab.content}
        </ReactMarkdown>
      </div>
    </div>
  );
}
