import { transition } from "../components/util/transition";
import * as classes from "../css/about-me-physiolymp.module.css";
import imageFrame from "../images/Designelement Bilderrahmen Viereck hoch.svg";
import imageTriangleSmall from "../images/Physiotherapeut mit begeisterung dreieck klein.svg";
import imageTriangleLarge from "../images/physiotherapeut mit begeisterung dreieck groß.svg";
import { StrapiImage } from "../util";
import React from "react";
import { useInView } from "react-intersection-observer";
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";

interface AboutMePhysiolympProps {
  titleLine1: string;
  titleLine2: string;
  name: string;
  description: string;
  image: StrapiImage;
  anchor: string;
}

export default function AboutMePhysiolymp(
  props: AboutMePhysiolympProps
): React.ReactElement {
  const { ref, inView } = useInView({
    threshold: 0,
    rootMargin: "400px 0px -400px 0px",
    initialInView: true,
  });

  return (
    <>
      {props.anchor && (
        <div className="relative z-50">
          <span id={props.anchor} className={classes.anchor} />
        </div>
      )}
      <section
        className="relative z-20 overflow-hidden pointer-events-none"
        style={{
          marginTop: "-28.5rem",
          transform: "skewY(9.2deg)",
        }}
        ref={ref}
      >
        <div
          className="bg-gray-200 text-black pointer-events-auto"
          style={{
            transform: "skewY(-14.2deg)",
            marginTop: "15rem",
            marginBottom: "-14.5rem",
          }}
        >
          <div className={classes.container}>
            <h2
              className="text-right lg:text-left xl:leading-normal"
              style={{
                transition: transition(inView, 0),
                transform: `translateY(${inView ? "0" : "-1rem"})`,
                opacity: inView ? 1 : 0,
              }}
              data-test="ueber-mich-title"
            >
              <span className={classes.subtitle}>{props.titleLine1}</span>
              <br />
              <span className={classes.title}>{props.titleLine2}</span>
            </h2>
            <img
              src={imageFrame}
              alt=""
              width="200"
              className={classes.imageFrame}
              style={{
                transition: transition(inView, 700),
                transform: `rotate(10deg) translateY(${
                  inView ? "0" : "-1rem"
                })`,
                opacity: inView ? 1 : 0,
              }}
            />
            <img
              aria-hidden="true"
              src={imageTriangleLarge}
              alt=""
              width="20"
              className={classes.largeTriangle}
              style={{
                transition: transition(inView, 850),
                transform: `rotate(var(--rotation)) translateY(${
                  inView ? "0" : "-1rem"
                })`,
                opacity: inView ? 1 : 0,
              }}
            />
            <img
              aria-hidden="true"
              src={imageTriangleSmall}
              alt=""
              width="20"
              className={classes.smallTriangle}
              style={{
                transition: transition(inView, 1000),
                transform: `rotate(var(--rotation)) translateY(${
                  inView ? "0" : "-1rem"
                })`,
                opacity: inView ? 1 : 0,
              }}
            />
            <div
              aria-label={props.image.alternativeText}
              className={classes.image}
              style={
                {
                  "--background": `url(${process.env.GATSBY_STRAPI_MEDIA_URL}${props.image.url})`,
                  transition: transition(inView, 700),
                  transform: `rotate(var(--rotation)) translate(var(--translation-x), var(--translation-y)) translateY(${
                    inView ? "0" : "-1rem"
                  })`,
                  opacity: inView ? 1 : 0,
                } as never
              }
            />
            <div className="flex flex-col-reverse xl:flex-col">
              <div
                className={classes.text}
                style={{
                  transition: transition(inView, 150),
                  transform: `translateY(${inView ? "0" : "-1rem"})`,
                  opacity: inView ? 1 : 0,
                }}
              >
                <ReactMarkdown rehypePlugins={[rehypeRaw]}>
                  {props.description}
                </ReactMarkdown>
              </div>
              <div
                className={classes.author}
                style={{
                  transition: transition(inView, 300),
                  transform: `translateY(${inView ? "0" : "-1rem"})`,
                  opacity: inView ? 1 : 0,
                }}
              >
                {props.name}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
