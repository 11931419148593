import * as spinner from "../css/spinner.module.css";
import React from "react";

export default function Spinner(): React.ReactElement {
  return (
    <svg className={spinner.spinner} viewBox="0 0 50 50">
      <circle
        className={spinner.path}
        cx="25"
        cy="25"
        r="20"
        fill="none"
        strokeWidth="5"
      ></circle>
    </svg>
  );
}
