import { transition } from "../components/util/transition";
import * as classes from "../css/text-section-with-image-on-right-physiolymp.module.css";
import frameTriangle from "../images/Designelement Bilderrahmen Dreieck.svg";
import triangleLarge from "../images/erhalte deine beweglichkeit dreieck groß.svg";
import triangleSmall from "../images/erhalte deine beweglichkeit dreieck klein.svg";
import { StrapiImage } from "../util";
import React from "react";
import { useInView } from "react-intersection-observer";
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";

interface TextSectionWithImageOnRightPhysiolympProps {
  titleLine1: string;
  titleLine2: string;
  text: string;
  anchor: string;
  image: StrapiImage;
}

export default function TextSectionWithImageOnRightPhysiolymp(
  props: TextSectionWithImageOnRightPhysiolympProps
): React.ReactElement {
  const { ref, inView } = useInView({
    threshold: 0.2,
    rootMargin: "400px 0px -400px 0px",
    initialInView: true,
  });

  return (
    <section className={classes.section} ref={ref}>
      <span id={props.anchor} className={classes.anchor} />
      <div className="skew-y-10 transform bg-gray-200 mt-72 md:mt-56 pt-20 xl:pt-0 pb-96 -mb-72 text-black">
        <div className="-skew-y-4 transform pb-10">
          <div className="max-w-screen-2xl m-auto px-6 md:px-14 relative">
            <img
              height="10"
              src={frameTriangle}
              alt="Anregung zur Selbstheilung Bild"
              className={classes.frameTriangle}
              style={{
                transition: transition(inView, 0),
                transform: `translate(50%, ${inView ? "0" : "-1rem"})`,
                opacity: inView ? 1 : 0,
              }}
            />
            <img
              aria-hidden="true"
              height="10"
              src={triangleLarge}
              alt=""
              className={classes.largeTriangle}
              style={{
                transition: transition(inView, 150),
                transform: `translate(0, ${inView ? "0" : "-1rem"})`,
                opacity: inView ? 1 : 0,
              }}
            />
            <img
              aria-hidden="true"
              height="10"
              src={triangleSmall}
              alt=""
              className={classes.smallTriangle}
              style={{
                transition: transition(inView, 300),
                transform: `translate(0, ${inView ? "0" : "-1rem"})`,
                opacity: inView ? 1 : 0,
              }}
            />
            <div
              aria-label={props.image.alternativeText}
              className={classes.image}
              style={
                {
                  "--background": `url(${process.env.GATSBY_STRAPI_MEDIA_URL}${props.image.url})`,
                  transition: transition(inView, 150),
                  transform: `translate(var(--translation-x), calc(${
                    inView ? "0px" : "-1rem"
                  } + var(--translation-y)))`,
                  opacity: inView ? 1 : 0,
                } as never
              }
            />
            <div className="text-right md:text-left xl:leading-loose">
              <span
                className={classes.subtitle}
                style={{
                  transition: transition(inView, 450),
                  transform: `translateY(${inView ? "0" : "-1rem"})`,
                  opacity: inView ? 1 : 0,
                }}
              >
                {props.titleLine1}
              </span>{" "}
              <span
                className={classes.title}
                style={{
                  transition: transition(inView, 550),
                  transform: `translateY(${inView ? "0" : "-1rem"})`,
                  opacity: inView ? 1 : 0,
                }}
              >
                {props.titleLine2}
              </span>
            </div>
            <div
              className={`md:ml-5 xl:mt-16 pt-2 ${classes.content}`}
              style={{
                transition: transition(inView, 850),
                transform: `translateY(${inView ? "0" : "-1rem"})`,
                opacity: inView ? 1 : 0,
              }}
            >
              <ReactMarkdown rehypePlugins={[rehypeRaw]}>
                {props.text}
              </ReactMarkdown>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
