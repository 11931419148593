import "../css/simple-text.css";
import React from "react";
import ReactMarkdown from "react-markdown";

export interface SimpleTextProps {
  id: number;
  strapi_comonent: "content.simple-text";
  Text: string;
}

function SimpleText(props: SimpleTextProps): React.ReactElement {
  return (
    <div className="content--simple-text">
      <ReactMarkdown>{props.Text}</ReactMarkdown>
    </div>
  );
}

export default SimpleText;
