// extracted by mini-css-extract-plugin
export var active = "methods-physiolymp-module--active--Nu2kc";
export var backdrop = "methods-physiolymp-module--backdrop--dADKk";
export var content = "methods-physiolymp-module--content--6KHkc";
export var left = "methods-physiolymp-module--left--Yz8ns";
export var navigationTab = "methods-physiolymp-module--navigation-tab--eVRKY";
export var placeholder = "methods-physiolymp-module--placeholder--zgt3u";
export var right = "methods-physiolymp-module--right--av+mc";
export var subtitle = "methods-physiolymp-module--subtitle--iNXGl";
export var tabContainer = "methods-physiolymp-module--tab-container--LfdWJ";
export var tabTriangle = "methods-physiolymp-module--tab-triangle--6g2aJ";
export var tabTriangleNextIndicator = "methods-physiolymp-module--tab-triangle-next-indicator--d4ThH";
export var tabs = "methods-physiolymp-module--tabs--5gxfH";
export var text = "methods-physiolymp-module--text--jWwuP";
export var title = "methods-physiolymp-module--title--ue1Zd";
export var titleContainer = "methods-physiolymp-module--title-container--blI0I";