import { transition } from "../components/util/transition";
import * as classes from "../css/dark-with-image-below-physiolymp.module.css";
import imageFrame from "../images/Designelement Bilderrahmen Viereck breit.svg";
import triangleOrange from "../images/Physiolymp Designelement Dreick klein Orange.svg";
import triangleYellow from "../images/Physiolymp Designelement Dreieck klein gelb.svg";
import { StrapiImage } from "../util";
import React from "react";
import { useInView } from "react-intersection-observer";
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";

export interface DarkWithImageBelowProps {
  subtitle: string;
  title: string;
  text: string;
  anchor: string;
  image: StrapiImage;
}

export default function DarkWithImageBelow(
  props: DarkWithImageBelowProps
): React.ReactElement {
  const { ref, inView } = useInView({
    threshold: 0,
    rootMargin: "100px 0px -100px 0px",
    initialInView: true,
  });

  const { ref: imageRef, inView: imageInView } = useInView({
    threshold: 0,
    rootMargin: "200px 0px -200px 0px",
    initialInView: true,
  });

  return (
    <section className="relative" ref={ref}>
      <div className={classes.container}>
        <span id={props.anchor} className={classes.anchor} />
        <div className="flex flex-col-reverse md:flex-col">
          <div
            className={classes.dimmedTitle}
            style={{
              transition: transition(inView, 0),
              transform: `translateY(${inView ? "0" : "-1rem"})`,
              opacity: inView ? 1 : 0,
            }}
          >
            {props.subtitle}
          </div>
          <div
            className={classes.title}
            style={{
              transition: transition(inView, 100),
              transform: `translateY(${inView ? "0" : "-1rem"})`,
              opacity: inView ? 1 : 0,
            }}
          >
            <ReactMarkdown rehypePlugins={[rehypeRaw]}>
              {props.title}
            </ReactMarkdown>
          </div>
        </div>
      </div>
      <div
        className={classes.text}
        style={{
          transition: transition(inView, 300),
          transform: `translateY(${inView ? "0" : "-1rem"})`,
          opacity: inView ? 1 : 0,
        }}
      >
        <ReactMarkdown rehypePlugins={[rehypeRaw]}>{props.text}</ReactMarkdown>
      </div>
      {/*

        To clarify some of the calculations below:

        112rem = full desktop screen width at 100% scale
        112rem is set as the target width for the full design proposal

        To position the elements more easily, this 112rem are used as the
        baseline for calculation positions when resizing horizontally.

        100vw = full browser width (dynamic)
        50vw  = half browser width (dynamic)
        ...

        (112rem - 100vw) * [factor] = size increase by [factor] at browser width 0

        This value is 0 if the browser has the width of the design proposal.
        It is increased to [factor] linearly by reducing the browser width to 0.

        ~ Tobias Faust (tf@ambimax.de)

        */}
      <div
        className="relative m-auto"
        style={{
          maxWidth: "107rem",
          height: "calc(31.7rem - (112rem - 100vw) * 0.2)",
          maxHeight: "31.7rem",
        }}
        ref={imageRef}
      >
        <img
          src={imageFrame}
          alt="Bilderrahmen"
          aria-hidden="true"
          className="absolute left-0 top-full z-10 select-none pointer-events-none"
          style={{
            width: "calc(60.7rem - max(0rem, 112rem - 100vw) * 0.37)",
            maxWidth: "60.7rem",
            transition: transition(imageInView, 0),
            transform: `translate(0.4rem, calc(-28rem + max(0rem, 112rem - 100vw) * 0.21)) translateY(${
              imageInView ? "1rem" : "0"
            })`,
            opacity: imageInView ? 1 : 0,
          }}
        />
        <img
          src={triangleYellow}
          alt="gelbes Dreieck"
          aria-hidden="true"
          className="absolute left-1/2 top-full z-10 select-none pointer-events-none"
          style={{
            width: "calc(16.1rem - (112rem - 100vw) * 0.1)",
            height: "auto",
            maxWidth: "16.1rem",
            transition: transition(imageInView, 100),
            transform: `translate(calc(3.35rem + max(0rem, 112rem - 100vw) * 0.08), calc(-12.7rem + max(0rem, 112rem - 100vw) * 0.1)) translateY(${
              imageInView ? "1rem" : "0"
            })`,
            opacity: imageInView ? 1 : 0,
          }}
          width="0"
          height="0"
        />
        <img
          src={triangleOrange}
          alt="orangenes Dreieck"
          aria-hidden="true"
          className="absolute left-1/2 top-full z-10 select-none pointer-events-none"
          style={{
            width: "calc(7.25rem - (112rem - 100vw) * 0.05)",
            height: "auto",
            maxWidth: "7.25rem",
            transition: transition(imageInView, 300),
            transform: `translate(calc(24.2rem - max(0rem, 112rem - 100vw) * 0.05), calc(-17rem + max(0rem, 112rem - 100vw) * 0.15)) translateY(${
              imageInView ? "1rem" : "0"
            })`,
            opacity: imageInView ? 1 : 0,
          }}
          width="0"
          height="0"
        />
        <div
          className={classes.image}
          style={
            {
              "--background": `url(${process.env.GATSBY_STRAPI_MEDIA_URL}${props.image.url})`,
              transition: transition(imageInView, 300),
              transform: `translate(-50%, ${imageInView ? "1rem" : "0"})`,
              opacity: imageInView ? 1 : 0,
            } as never
          }
        ></div>
      </div>
    </section>
  );
}
