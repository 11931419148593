import React from "react";
import { LinkProps, scroller } from "react-scroll";

export default function SmoothLink(props: LinkProps): React.ReactElement {
  return (
    <a
      href={`#${props.to}`}
      onClick={(ev) => {
        const _scrollX = scrollX;
        const _scrollY = scrollY;

        ev.preventDefault();

        if (props.to === "start") {
          window.location.hash = "";
        } else {
          window.location.hash = props.to;
        }

        setTimeout(() => {
          window.scrollTo({
            left: _scrollX,
            top: _scrollY,
          });
          scroller.scrollTo(props.to, {
            ...props,
            smooth: true,
            duration: 750,
          });
        }, 0);
      }}
      {...(props as any)}
    >
      {props.children}
    </a>
  );
}
