import { transition } from "../components/util/transition";
import * as classes from "../css/white-with-black-text-physiolymp.module.css";
import React from "react";
import { useInView } from "react-intersection-observer";
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";

export interface SimpleTextProps {
  text: string;
}

export default function SimpleText(props: SimpleTextProps): React.ReactElement {
  const { ref, inView } = useInView({
    threshold: 0,
    rootMargin: "1000px 0px -500px 0px",
    initialInView: true,
  });

  return (
    <div
      ref={ref}
      className="relative bg-white z-10 text-black overflow-hidden"
      style={{
        marginTop: "-16.25rem",
        paddingBottom: "18rem",
        transform: "skewY(7.5deg)",
      }}
    >
      <div
        className="m-auto"
        style={{
          paddingTop: "9rem",
          maxWidth: "83em",
          transition: transition(inView, 0),
          transform: `translateY(${inView ? "0" : "-1rem"}) skewY(-7.5deg)`,
          opacity: inView ? 1 : 0,
        }}
      >
        <div className={classes.content}>
          <ReactMarkdown rehypePlugins={[rehypeRaw]}>
            {props.text}
          </ReactMarkdown>
        </div>
      </div>
      <div
        className="absolute top-0 left-0 right-0 bg-yellow-600"
        style={{
          height: "5rem",
          transform: "translate(0.8rem, -2.5rem) skew(64deg, -2.5deg)",
        }}
      />
    </div>
  );
}
