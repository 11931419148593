import img1 from "../images/image-gallery/6C436E01-B596-4449-9271-13A09A41183F.jpeg";
import img3 from "../images/image-gallery/E6CDDB1F-5E4C-4072-8498-41A0DA95CF42.jpeg";
import img5 from "../images/image-gallery/IMG_0417.jpeg";
import img6 from "../images/image-gallery/IMG_5529.jpeg";
import img7 from "../images/image-gallery/IMG_5532.jpeg";
import img8 from "../images/image-gallery/IMG_5536.jpeg";
import img9 from "../images/image-gallery/IMG_8966.png";
import img10 from "../images/image-gallery/IMG_9255.jpeg";
import img11 from "../images/image-gallery/IMG_9256.jpeg";
import img4 from "../images/image-gallery/f9b8318c-4752-4c4c-9ccc-c31fdd54b534.jpeg";
import React, { useState } from "react";

const images = [
  img1,
  img3,
  img4,
  img5,
  img6,
  img7,
  img8,
  img9,
  img10,
  img11,
];

export default function PhysiolympImageGallery(): React.ReactElement {
  const [selected, setSelected] = useState(0);

  return (
    <div className="mx-auto my-8 px-4" style={{ maxWidth: "1280px" }}>
      <div
        className="ring-2 ring-gray-700 rounded-sm"
        style={{
          backgroundImage: `url(${images[selected]})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          paddingBottom: "60%",
        }}
      ></div>
      <div className="grid grid-cols-3 md:grid-cols-4 mt-2 gap-2 lg:mt-6 lg:gap-6">
        {images.map((img, idx) => (
          <div
            key={img}
            className="ring-2 ring-gray-700 hover:ring-3 hover:ring-gray-400 cursor-pointer rounded-sm transition-all"
            style={{
              backgroundImage: `url(${img})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              paddingBottom: "60%",
              filter: selected !== idx ? "grayscale(90%) brightness(80%)" : "",
            }}
            onClick={() => setSelected(idx)}
          />
        ))}
      </div>
    </div>
  );
}
