// extracted by mini-css-extract-plugin
export var collapse = "navigation-module--collapse--rSA1o";
export var contactButton = "navigation-module--contact-button--FTk+6";
export var container = "navigation-module--container--Cf5ye";
export var item = "navigation-module--item--htMPV";
export var items = "navigation-module--items--lz8JH";
export var logo = "navigation-module--logo--uxVKP";
export var logoAdditional = "navigation-module--logo-additional--do9jT";
export var logoName = "navigation-module--logo-name--jRlHv";
export var navigation = "navigation-module--navigation--yLM3W";
export var opaque = "navigation-module--opaque--HQi4w";