import Spinner from "../components/spinner";
import { transition } from "../components/util/transition";
import * as classes from "../css/contact-physiolymp.module.css";
import imageFrame from "../images/Designelement Bilderrahmen Viereck Kontakt.svg";
import timLogo from "../images/Physiolymp Typo Logo Schriftzug weiß.svg";
import contactIllustration from "../images/Physiolymp_Kontakt_2023.png";
import { PageQueryData } from "../templates/page";
import React from "react";
import { useInView } from "react-intersection-observer";
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";

const contactApiEndpoint =
  "https://sqefam7nzj.execute-api.eu-central-1.amazonaws.com/prod";

interface ContactPhysiolympProps {
  pageQueryData: PageQueryData;
  anchor: string;
  title: string;
  description: string;
  messagePlaceholder: string;
  consentText: string;
  submitText: string;
}

export default function ContactPhysiolymp(
  props: ContactPhysiolympProps
): React.ReactElement {
  const [isWorking, setIsWorking] = React.useState(false);
  const [isSubmitted, setIsSubmitted] = React.useState(false);
  const [isSubmitMobileDialogOpen, setIsSubmitMobileDialogOpen] =
    React.useState(false);
  const [error, setError] = React.useState<string | null>(null);
  const { ref, inView } = useInView({
    threshold: 0,
    rootMargin: "300px 0px -300px 0px",
    initialInView: true,
  });

  async function handleSubmit(event: React.FormEvent) {
    event.preventDefault();

    try {
      setIsWorking(true);
      setError(null);

      if (firstName.value.trim() === "") {
        setError("Bitte einen Vornamen angeben!");
        return;
      }

      if (lastName.value.trim() === "") {
        setError("Bitte einen Nachnamen angeben!");
        return;
      }

      if (email.value.trim() === "") {
        setError("Bitte eine E-Mail angeben!");
        return;
      }

      if (phone.value.trim() === "") {
        setError("Bitte eine Telefonnummer angeben!");
        return;
      }

      if (message.value.trim() === "") {
        setError("Bitte eine Nachricht angeben!");
        return;
      }

      if (!consent.checked) {
        setError("Bitte der Datenschutzerklärung zustimmen!");
        return;
      }

      const response = await fetch(`${contactApiEndpoint}/send`, {
        method: "post",
        headers: {
          "content-type": "application/json",
        },
        body: JSON.stringify({
          firstName: firstName.value,
          lastName: lastName.value,
          email: email.value,
          phone: phone.value,
          message: message.value,
        }),
      });

      await response.json();
      await new Promise((resolve) => setTimeout(resolve, 2000));

      setIsSubmitted(true);
      setIsSubmitMobileDialogOpen(true);
    } catch {
      setError("Es ist ein Fehler bei der Übertragung aufgetreten!");
    } finally {
      setIsWorking(false);
    }
  }

  const firstName = useInput("");
  const lastName = useInput("");
  const email = useInput("");
  const phone = useInput("");
  const message = useInput("");
  const consent = useCheckbox(false);

  return (
    <section className="relative" ref={ref}>
      {props.anchor && <span id={props.anchor} className={classes.anchor} />}
      <div className="overflow-hidden">
        <img
          src={timLogo}
          alt="Physiolymp Logo"
          width="500"
          className={classes.logo}
          data-test="contact-logo"
        />
      </div>
      <div
        className="m-auto flex flex-col-reverse md:flex-row px-6 py-12 items-center"
        style={{
          maxWidth: "93.75rem",
        }}
      >
        <div
          className={classes.imageContainer}
          style={{
            transition: transition(inView, 300),
            transform: `translateY(${inView ? "0" : "-1rem"})`,
            opacity: inView ? 1 : 0,
          }}
        >
          <img
            className={classes.imageFrame}
            src={imageFrame}
            alt="Kontakt Bild"
            width="200"
          />
          <div
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              clipPath: "polygon(30% 10%, 95% 0%, 70% 90%, 10% 90%)",
              transform: "translate(-1rem, 4rem)",
              userSelect: "none",
            }}
          >
            <img
              src={contactIllustration}
              alt=""
              width="auto"
              style={{ objectFit: "cover" }}
            />
          </div>
        </div>
        <div
          className={`flex-1 text-center font-light flex flex-col justify-between ${classes.text}`}
          style={{
            maxWidth: "39.75rem",
          }}
        >
          <div>
            <h2
              className="text-white font-semibold uppercase"
              style={{
                fontSize: "2.75rem",
                paddingBottom: "0.7rem",
                transition: transition(inView, 0),
                transform: `translateY(${inView ? "0" : "-1rem"})`,
                opacity: inView ? 1 : 0,
              }}
              data-test="contact-title"
            >
              {props.pageQueryData.strapiContactPhysiolymp.title}
            </h2>
            <div
              style={{
                transition: transition(inView, 150),
                transform: `translateY(${inView ? "0" : "-1rem"})`,
                opacity: inView ? 1 : 0,
              }}
            >
              <div data-test="contact-text">
                <ReactMarkdown rehypePlugins={[rehypeRaw]}>
                  {props.pageQueryData.strapiContactPhysiolymp.description}
                </ReactMarkdown>
              </div>
              {isWorking && (
                <p className="mt-8 text-gray-600 flex-row items-center justify-center gap-4 select-none hidden md:flex">
                  <Spinner />
                  Ihre Anfrage wird übermittelt...
                </p>
              )}
              {error && (
                <p className="mt-8 text-gray-400 flex-row items-center justify-center gap-4 select-none hidden md:flex">
                  <ErrorSvg />
                  {error}
                </p>
              )}
            </div>
          </div>
          {!isSubmitted ? (
            <form
              onSubmit={handleSubmit}
              style={{
                transition: transition(inView, 0),
                transform: `translateY(${inView ? "0" : "-1rem"})`,
                opacity: inView ? 1 : 0,
              }}
            >
              <div
                className="grid pt-0 sm:pt-10 sm:grid-cols-2"
                style={{
                  paddingBottom: "2.9rem",
                  gap: "2.38rem",
                }}
              >
                <div className="flex flex-col" style={{ gap: "0.5rem" }}>
                  <input
                    type="text"
                    name="firstname"
                    disabled={isWorking}
                    className="border-b border-gray-600 bg-transparent text-center focus:outline-none focus:border-yellow-400"
                    style={{
                      padding: "0.4rem 0",
                    }}
                    {...firstName}
                  />
                  <span
                    className="uppercase font-semibold text-gray-600"
                    style={{ fontSize: "0.8rem" }}
                  >
                    Vorname*
                  </span>
                </div>
                <div className="flex flex-col" style={{ gap: "0.5rem" }}>
                  <input
                    type="text"
                    name="lastname"
                    disabled={isWorking}
                    className="border-b border-gray-600 bg-transparent text-center focus:outline-none focus:border-yellow-400"
                    style={{
                      padding: "0.4rem 0",
                    }}
                    {...lastName}
                  />
                  <span
                    className="uppercase font-semibold text-gray-600"
                    style={{ fontSize: "0.8rem" }}
                  >
                    Nachname*
                  </span>
                </div>
                <div className="flex flex-col" style={{ gap: "0.5rem" }}>
                  <input
                    type="email"
                    name="email"
                    disabled={isWorking}
                    className="border-b border-gray-600 bg-transparent text-center focus:outline-none focus:border-yellow-400"
                    style={{
                      padding: "0.4rem 0",
                    }}
                    {...email}
                  />
                  <span
                    className="uppercase font-semibold text-gray-600"
                    style={{ fontSize: "0.8rem" }}
                  >
                    E-Mail*
                  </span>
                </div>
                <div className="flex flex-col" style={{ gap: "0.5rem" }}>
                  <input
                    type="text"
                    name="phone"
                    disabled={isWorking}
                    className="border-b border-gray-600 bg-transparent text-center focus:outline-none focus:border-yellow-400"
                    style={{
                      padding: "0.4rem 0",
                    }}
                    {...phone}
                  />
                  <span
                    className="uppercase font-semibold text-gray-600"
                    style={{ fontSize: "0.8rem" }}
                  >
                    Telefon*
                  </span>
                </div>
              </div>
              <textarea
                placeholder={
                  props.pageQueryData.strapiContactPhysiolymp.messagePlaceholder
                }
                name="message"
                disabled={isWorking}
                className="w-full bg-white bg-opacity-20 text-white focus:outline-none focus:ring-1 ring-yellow-400"
                style={{
                  marginBottom: "0.9rem",
                  height: "5.2rem",
                  fontSize: "1.1rem",
                  padding: "0.5rem 0.8rem",
                }}
                {...message}
              ></textarea>
              <label
                className="text-left text-gray-400 flex flex-row gap-3"
                style={{
                  fontSize: "0.8rem",
                  lineHeight: 1.2,
                  paddingBottom: "3.35rem",
                }}
              >
                <input
                  type="checkbox"
                  name="consent"
                  className="transform translate-y-0.5"
                  {...consent}
                />
                <ReactMarkdown rehypePlugins={[rehypeRaw]}>
                  {props.pageQueryData.strapiContactPhysiolymp.consentText}
                </ReactMarkdown>
              </label>
              <button
                disabled={isWorking}
                className="bg-yellow-400 uppercase text-black font-semibold cursor-pointer disabled:text-opacity-30 disabled:cursor-not-allowed focus:outline-none focus:ring ring-yellow-600"
                style={{
                  fontSize: "1.05rem",
                  height: "3.5rem",
                  padding: "0 1.9rem",
                }}
              >
                {props.pageQueryData.strapiContactPhysiolymp.submitText}
              </button>
            </form>
          ) : (
            <div
              style={{
                transition: transition(inView, 0),
                transform: `translateY(${inView ? "0" : "-1rem"})`,
                opacity: inView ? 1 : 0,
              }}
              className="flex-1 flex justify-center text-gray-500 select-none mt-12"
            >
              Vielen Dank für Ihre Anfrage!
            </div>
          )}
        </div>
      </div>
      {error && (
        <MobileOnlyDialog onClose={() => setError("")} closeText="Ok">
          <ErrorSvg />
          {error}
        </MobileOnlyDialog>
      )}
      {isWorking && (
        <MobileOnlyDialog>
          <Spinner />
          Ihre Anfrage wird übermittelt...
        </MobileOnlyDialog>
      )}
      {isSubmitMobileDialogOpen && (
        <MobileOnlyDialog
          onClose={() => setIsSubmitMobileDialogOpen(false)}
          closeText="Schließen"
        >
          Vielen Dank für Ihre Anfrage!
        </MobileOnlyDialog>
      )}
    </section>
  );
}

function useInput(defaultValue = "") {
  const [value, setValue] = React.useState<string>(defaultValue);

  return {
    value,
    onInput: (ev: React.FormEvent) => {
      setValue((ev.target as HTMLInputElement).value);
    },
  };
}

function useCheckbox(defaultValue = false) {
  const [checked, setChecked] = React.useState<boolean>(defaultValue);

  return {
    checked,
    onChange: (ev: React.FormEvent) => {
      setChecked((ev.target as HTMLInputElement).checked);
    },
  };
}

const ErrorSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className="h-6 w-6 text-yellow-300 flex-none"
    fill="none"
    viewBox="0 0 24 24"
    stroke="currentColor"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
    />
  </svg>
);

interface MobileOnlyDialogProps {
  children?: React.ReactNode;
  onClose?: () => void;
  closeText?: string;
}

const MobileOnlyDialog = (props: MobileOnlyDialogProps) => {
  return (
    <div className="md:hidden">
      <div
        className="fixed top-0 right-0 bottom-0 left-0 bg-gray-800 opacity-70"
        onClick={() => props.onClose?.()}
      />
      <div
        className="fixed p-8 top-1/2 left-1/2 w-full transform -translate-x-1/2 -translate-y-1/2 flex justify-center items-center"
        onClick={() => props.onClose?.()}
      >
        <div
          className="rounded bg-gray-700 max-w-max p-4 flex flex-col gap-6 items-center shadow-md"
          onClick={(ev) => {
            ev.stopPropagation();
          }}
        >
          <p className="flex flex-col gap-2 items-center">{props.children}</p>
          {props.closeText && (
            <button
              className="px-4 py-1 bg-yellow-300 text-black font-semibold rounded"
              onClick={() => props.onClose?.()}
            >
              {props.closeText}
            </button>
          )}
        </div>
      </div>
    </div>
  );
};
