import ContentPlaceholder from "../components/content-placeholder";
import { LinkProps } from "../components/link";
import Navigation from "../components/navigation";
import PhysiolympFooter from "../components/physiolymp-footer";
import content from "../content";
import { StrapiImage } from "../util";
import { graphql, PageProps } from "gatsby";
import React from "react";

export interface PageQueryData {
  strapiPage: {
    Content: ContentEntry[];
  };

  strapiContactPhysiolymp: {
    title: string;
    description: string;
    messagePlaceholder: string;
    consentText: string;
    submitText: string;
    apiURL: string;
  };

  allStrapiPhysiolympPatient: {
    nodes: {
      name: string;
      logo: StrapiImage;
    }[];
  };

  strapiPhysiolympFooter: {
    headerText?: string;
    instagramUsername?: string;
    links: {
      text: string;
      link: LinkProps;
    }[];
    additionalText1: string;
    additionalText2: string;
  };
}

interface ContentEntry {
  strapi_component: string;
}

function PageTemplate(props: PageProps<PageQueryData>): React.ReactNode {
  const page = props.data;

  return (
    <div>
      <Navigation />
      {page.strapiPage.Content.map((content, idx) =>
        createContentComponent(content, idx, page)
      )}
      <PhysiolympFooter {...page.strapiPhysiolympFooter}></PhysiolympFooter>
    </div>
  );
}

function createContentComponent(
  entry: ContentEntry,
  idx: number,
  pageQueryData: PageQueryData
) {
  const Component = (
    content as never as Record<
      string,
      React.ComponentClass<{ pageQueryData: PageQueryData }>
    >
  )[entry.strapi_component];
  if (!Component) {
    return (
      <ContentPlaceholder
        name={`<unknown>: ${entry.strapi_component.split(".")[1]}`}
        props={entry}
        key={idx}
      />
    );
  }

  return (
    <Component {...entry} key={idx} pageQueryData={pageQueryData}></Component>
  );
}

export default PageTemplate;

export const pageQuery = graphql`
  query ($slug: String!) {
    strapiPage(Slug: { eq: $slug }) {
      Content
    }
    strapiContactPhysiolymp {
      title
      description
      messagePlaceholder
      submitText
      consentText
      apiURL
    }

    allStrapiPhysiolympPatient {
      nodes {
        name
        logo {
          url
          alternativeText
        }
      }
    }

    strapiPhysiolympFooter {
      headerText
      instagramUsername
      links {
        text
        link {
          type
          external
          openInNewTab
          anchor
          page {
            Slug
          }
        }
      }
      additionalText1
      additionalText2
    }
  }
`;
