import { transition } from "../components/util/transition";
import * as classes from "../css/text-section-with-title-on-left-physiolymp.module.css";
import React from "react";
import { useInView } from "react-intersection-observer";
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";

interface TextSectionWithTitleOnLeftPhysiolympProps {
  titleLine1: string;
  titleLine2: string;
  text: string;
  anchor: string;
}

export default function TextSectionWithTitleOnLeftPhysiolymp(
  props: TextSectionWithTitleOnLeftPhysiolympProps
): React.ReactElement {
  const { ref, inView } = useInView({
    threshold: 0.1,
    rootMargin: "100px 0px -100px 0px",
    initialInView: true,
  });

  return (
    <section ref={ref} className={classes.section}>
      <div className="skew-y-8 transform pb-10">
        <div className="max-w-screen-2xl m-auto px-6 xl:px-14">
          <div className="flex flex-col md:flex-row justify-between gap-4 md:gap-12 xl:gap-44">
            <div
              className="flex-1 text-right md:text-left xl:leading-loose"
              role="presentation"
            >
              <span
                className={classes.subtitle}
                style={{
                  transition: transition(inView, 0),
                  transform: `translateY(${inView ? "0" : "-1rem"})`,
                  opacity: inView ? 1 : 0,
                }}
              >
                {props.titleLine1}
              </span>{" "}
              <span
                className={classes.title}
                style={{
                  letterSpacing: 0.8,
                  transition: transition(inView, 100),
                  transform: `translateY(${inView ? "0" : "-1rem"})`,
                  opacity: inView ? 1 : 0,
                }}
              >
                {props.titleLine2}
              </span>
            </div>
            <div
              className={`${classes.content} flex-1 xl:-ml-10`}
              style={{
                transition: transition(inView, 400),
                transform: `translateY(${inView ? "0" : "-1rem"})`,
                opacity: inView ? 1 : 0,
              }}
            >
              <span
                id={props.anchor}
                style={{
                  position: "relative",
                  top: "calc(-10rem - 10vw)",
                }}
              />
              <ReactMarkdown rehypePlugins={[rehypeRaw]}>
                {props.text}
              </ReactMarkdown>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
