import React from "react";
import ReactMarkdown from "react-markdown";

interface StrapiImageData {
  name: string;
  hash: string;
  ext: string;
  mime: string;
  width: number;
  height: number;
  size: number;
  path: string | null;
  url: string;
}

interface StrapiImage extends StrapiImageData {
  alternativeText: string;
  caption: string;
  formats: Record<"thumbnail" | "large" | "medium" | "small", StrapiImageData>;
}

export interface HeroProps {
  id: number;
  strapi_comonent: "content.hero";
  Text: string;
  Image: StrapiImage;
}

function Hero(props: HeroProps): React.ReactNode {
  return (
    <div className="p-4">
      <div>TODO: Image</div>
      <div>
        <ReactMarkdown>{props.Text}</ReactMarkdown>
      </div>
    </div>
  );
}

export default Hero;
