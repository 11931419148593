import instagramIcon from "../images/Instagram.svg";
import { PageQueryData } from "../templates/page";
import { Link } from "./link";
import React from "react";
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";

export type PhysiolympFooterProps = PageQueryData["strapiPhysiolympFooter"];

export default function PhysiolympFooter(
  props: PhysiolympFooterProps
): React.ReactElement {
  return (
    <footer className="pb-10">
      <div className="bg-gray-300 bg-opacity-10 sm:bg-opacity-20 h-12 sm:h-24 px-12 flex justify-center items-center">
        <div className="flex flex-row gap-8 items-center sm:justify-center flex-none w-72 sm:w-auto">
          {props.headerText && (
            <div className="uppercase opacity-90">{props.headerText}</div>
          )}
          <div className="select-none">
            {props.instagramUsername && (
              <a href={`https://www.instagram.com/${props.instagramUsername}/`}>
                <img
                  src={instagramIcon}
                  alt="Instagram"
                  width="41"
                  className="opacity-70 hover:opacity-90"
                />
              </a>
            )}
          </div>
        </div>
      </div>
      <div
        className="flex flex-row justify-center"
        style={{ fontSize: "0.8rem", marginTop: "2.5rem" }}
      >
        <div className="flex flex-col-reverse sm:flex-row gap-12 w-72 sm:w-auto">
          <div className="flex flex-row gap-8 items-center -mx-4 md:mx-0">
            {(props.links ?? []).map((link, index) => (
              <Link
                key={index}
                {...link.link}
                className="opacity-50 hover:opacity-80 p-4"
              >
                {link.text}
              </Link>
            ))}
          </div>
          <div className="flex flex-col sm:flex-row gap-8 opacity-50 border-gray-500 sm:border-l sm:pl-8">
            <div>
              <ReactMarkdown rehypePlugins={[rehypeRaw]}>
                {props.additionalText1}
              </ReactMarkdown>
            </div>
            <div className="pt-8 border-t sm:border-0 sm:pt-0 border-gray-500">
              <ReactMarkdown rehypePlugins={[rehypeRaw]}>
                {props.additionalText2}
              </ReactMarkdown>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}
