import { transition } from "../components/util/transition";
import * as classes from "../css/patient-slider-physiolymp.module.css";
import { PageQueryData } from "../templates/page";
import { StrapiImage } from "../util";
import { useEmblaCarousel } from "embla-carousel/react";
import React, { useEffect, useState } from "react";
import { useInView } from "react-intersection-observer";

interface PatientSliderPhysiolympProps {
  pageQueryData: PageQueryData;
}

export default function PatientSliderPhysiolymp(
  props: PatientSliderPhysiolympProps
): React.ReactElement {
  const { ref, inView } = useInView({
    threshold: 0,
    rootMargin: "200px 0px -200px 0px",
    initialInView: true,
  });

  const [emblaRef, embla] = useEmblaCarousel({
    loop: true,
    startIndex: 0,
  });

  const [selected, setSelected] = useState(0);

  useEffect(() => {
    if (!embla) {
      return;
    }

    embla.on("select", () => {
      setSelected(embla.selectedScrollSnap());
    });
  }, [embla]);

  return (
    <div ref={ref} className="py-12 md:py-32">
      <div
        className={classes.title}
        style={{
          transition: transition(inView, 0),
          transform: `translateY(${inView ? "0" : "-1rem"})`,
          opacity: inView ? 1 : 0,
        }}
      >
        Meine{" "}
        <span className="uppercase text-yellow-400 font-bold">Patienten</span>
      </div>
      <div className={classes.embla} ref={emblaRef}>
        <div className={classes.embla__container}>
          {props.pageQueryData.allStrapiPhysiolympPatient.nodes.map(
            (entry, index) => (
              <Slide
                key={index}
                index={index}
                selected={selected}
                entry={entry}
              ></Slide>
            )
          )}
        </div>
      </div>
    </div>
  );
}

interface SlideProps {
  selected: number;
  index: number;
  entry: {
    name: string;
    logo: StrapiImage;
  };
}

function Slide({ selected, index, entry }: SlideProps): React.ReactElement {
  return (
    <div
      className={`${classes.embla__slide} ${
        selected === index ? classes.embla__slide__selected : ""
      }`}
    >
      <div
        aria-label={entry.name}
        className={classes.logo}
        style={{
          backgroundImage: `url(${process.env.GATSBY_STRAPI_MEDIA_URL}${entry.logo.url})`,
          backgroundSize: "contain",
          backgroundPosition: "center center",
          backgroundRepeat: "no-repeat",
        }}
      ></div>
    </div>
  );
}
