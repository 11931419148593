import * as classes from "../css/navigation.module.css";
import contactIcon from "../images/Kontakticon phone& envelope.svg";
import SmoothLink from "./smoothlink";
import { useScrollPosition } from "@n8tb1t/use-scroll-position";
import React from "react";

export default function Navigation(): React.ReactElement {
  const [isOpaque, setIsOpaque] = React.useState(false);

  useScrollPosition(({ currPos }) => {
    const newOpaque = currPos.y < 0;
    if (newOpaque !== isOpaque) {
      setIsOpaque(newOpaque);
    }
  });

  const { setChecked: setIsMenuOpen, ...menuOpenCheckbox } = useCheckbox();
  const { checked: isMenuOpen } = menuOpenCheckbox;

  return (
    <div
      className={[classes.navigation, isOpaque && classes.opaque]
        .filter(Boolean)
        .join(" ")}
    >
      <div className={classes.container} onClick={() => setIsMenuOpen(false)}>
        <SmoothLink to="kontakt" className={classes.contactButton}>
          <img src={contactIcon} alt="Kontakt" className="w-7 h-7" />
        </SmoothLink>
        <SmoothLink className={classes.logo} to="start">
          <span className={classes.logoName}>Physiolymp</span>{" "}
          <span className={classes.logoAdditional}>Saar</span>
        </SmoothLink>
        <label
          htmlFor="navigation-collapse"
          className="pr-4 xl:hidden"
          onClick={(ev) => ev.stopPropagation()}
        >
          {isMenuOpen ? (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          ) : (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M4 6h16M4 12h16M4 18h16"
              />
            </svg>
          )}
        </label>
        <input
          id="navigation-collapse"
          type="checkbox"
          className={classes.collapse}
          {...menuOpenCheckbox}
        />
        <div className={classes.items}>
          <SmoothLink className={classes.item} to="physiotherapie">
            <span>Physiotherapie</span>
          </SmoothLink>
          <SmoothLink className={classes.item} to="osteopathie">
            <span>Osteopathie</span>
          </SmoothLink>
          <SmoothLink className={classes.item} to="konzept">
            <span>Konzept</span>
          </SmoothLink>
          <SmoothLink className={classes.item} to="behandlung">
            <span>Behandlung</span>
          </SmoothLink>
          <SmoothLink className={classes.item} to="ueber-mich">
            <span>Über mich</span>
          </SmoothLink>
          <SmoothLink className={classes.item} to="kontakt">
            <span>Kontakt</span>
          </SmoothLink>
        </div>
      </div>
    </div>
  );
}

function useCheckbox(defaultValue = false) {
  const [checked, setChecked] = React.useState<boolean>(defaultValue);

  return {
    checked,
    setChecked,
    onChange: (ev: React.FormEvent) => {
      setChecked((ev.target as HTMLInputElement).checked);
    },
  };
}
