import React from "react";

export interface ContentPlaceholderProps {
  name: string;
  props: unknown;
}

export default function ContentPlaceholder(
  props: ContentPlaceholderProps
): React.ReactElement {
  return (
    <div className="p-4 bg-gray-100 border-2 border-dashed border-gray-400 flex flex-col gap-4">
      <div>
        Component: <b>{props.name}</b>
      </div>
      <details>
        <summary className="cursor-pointer">props</summary>
        <code>
          <pre>{JSON.stringify(props, null, 4)}</pre>
        </code>
      </details>
    </div>
  );
}
