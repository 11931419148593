// easeOutExpo, easeInExpo
// https://easings.net/
const easeOutCirc = "cubic-bezier(0, 0.55, 0.45, 1)";
const easeInCirc = "cubic-bezier(0.7, 0, 0.84, 0)";
const transitionIn = (delay: number, time: number) =>
  `transform ${time}ms ${delay}ms ${easeOutCirc}, opacity ${time}ms ${delay}ms ${easeOutCirc}`;
const transitionOut = (delay: number, time: number) =>
  `transform ${time}ms ${delay}ms ${easeInCirc}, opacity ${time}ms ${delay}ms ${easeInCirc}`;

export const transition = (inView: boolean, delay: number): string =>
  inView ? transitionIn(delay, 1000) : transitionOut(0, 750);

export const transitionFast = (inView: boolean, delay: number): string =>
  inView ? transitionIn(delay, 500) : transitionOut(0, 250);
