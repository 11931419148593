// extracted by mini-css-extract-plugin
export var anchor = "about-me-physiolymp-module--anchor--iyvfs";
export var author = "about-me-physiolymp-module--author--XpDTK";
export var container = "about-me-physiolymp-module--container--lwezr";
export var image = "about-me-physiolymp-module--image--C5YoM";
export var imageFrame = "about-me-physiolymp-module--image-frame--6Yj5X";
export var largeTriangle = "about-me-physiolymp-module--large-triangle--pfWYJ";
export var smallTriangle = "about-me-physiolymp-module--small-triangle--c03Ge";
export var subtitle = "about-me-physiolymp-module--subtitle--iorNw";
export var text = "about-me-physiolymp-module--text--WXAq-";
export var title = "about-me-physiolymp-module--title--ym2vT";