import { Link, LinkProps } from "../components/link";
import * as classes from "../css/hero-physiolymp.module.css";
import telephoneIcon from "../images/Kontakticon phone& envelope.svg";
import { StrapiImage } from "../util";
import React from "react";
import ReactMarkdown from "react-markdown";
import Typewriter from "typewriter-effect";

export interface HeroProps {
  id: number;
  strapi_comonent: "content.hero";
  typewriterLine1: string;
  typewriterLine2: string;
  text: string;
  ctaText: string;
  ctaLink: LinkProps;
  bottomText: string;
  image: StrapiImage;
  anchor: string;
}

export default function Hero(props: HeroProps): React.ReactElement {
  const [cursorRow, setCursorRow] = React.useState(0);

  return (
    <div className="px-6 lg:px-2" id="start">
      <div className="max-w-screen-2xl m-auto pt-0 md:pt-20 lg:px-2">
        <div className="flex flex-row">
          <div className="pt-28 md:pt-36 pb-24 flex-1">
            <div className="flex flex-col lg:flex-row relative">
              <h1 className="flex flex-col uppercase md:gap-2 pb-1">
                <span
                  className={`${classes.ttext} font-semibold tracking-tight`}
                >
                  <Typewriter
                    onInit={(tw) => {
                      tw.changeDelay(80)
                        .typeString(props.typewriterLine1)
                        .start();
                    }}
                    options={{ cursor: "" }}
                  />
                  {cursorRow === 0 && (
                    <span aria-hidden="true" className={classes.cursor}>
                      |
                    </span>
                  )}
                </span>{" "}
                <span
                  className={`${classes.ttext} -mt-4 md:mt-0 font-light tracking-tighter text-yellow-400`}
                >
                  <Typewriter
                    onInit={async (tw) => {
                      await new Promise((resolve) => setTimeout(resolve, 1800));
                      setCursorRow(1);
                      tw.changeDelay(80)
                        .typeString(props.typewriterLine2)
                        .start();
                      await new Promise((resolve) => setTimeout(resolve, 3600));
                      setCursorRow(2);
                    }}
                    options={{ cursor: "" }}
                  />
                  {cursorRow === 1 && (
                    <span aria-hidden="true" className={classes.cursor}>
                      |
                    </span>
                  )}
                </span>
              </h1>
              <img
                height="100"
                src={`${process.env.GATSBY_STRAPI_MEDIA_URL}${props.image?.url}`}
                alt={props.image?.alternativeText}
                className={classes.logo}
              />
            </div>
            <div className={classes.text}>
              <p className="max-w-md pt-6 md:pt-14 pb-16 text-lg font-light tracking-wide leading-7 text-gray-300">
                <ReactMarkdown>{props.text}</ReactMarkdown>
              </p>
              <Link
                {...props.ctaLink}
                className="inline-flex flex-row items-center bg-yellow-400 text-black font-semibold text-xl py-3 pl-1.5 pr-8 gap-2 focus:outline-none focus:ring-2 ring-yellow-600"
              >
                <img
                  src={telephoneIcon}
                  alt="Telefon Icon"
                  style={{
                    width: "2rem",
                    height: "2rem",
                  }}
                />
                {props.ctaText}
              </Link>
              <p className={classes.contact}>
                <ReactMarkdown>{props.bottomText}</ReactMarkdown>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
