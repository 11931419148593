import aboutmephysiolymp from "./about-me-physiolymp";
import contactphysiolymp from "./contact-physiolymp";
import darkwithimagebelowphysiolymp from "./dark-with-image-below-physiolymp";
import herophysiolymp from "./hero-physiolymp";
import hero from "./hero";
import methodsphysiolymp from "./methods-physiolymp";
import patientsliderphysiolymp from "./patient-slider-physiolymp";
import physiolympimagegallery from "./physiolymp-image-gallery";
import simpletext from "./simple-text";
import textsectionwithimageonrightphysiolymp from "./text-section-with-image-on-right-physiolymp";
import textsectionwithtitleonleftphysiolymp from "./text-section-with-title-on-left-physiolymp";
import whitewithblacktextphysiolymp from "./white-with-black-text-physiolymp";
export default {
"content.about-me-physiolymp": aboutmephysiolymp,
"content.contact-physiolymp": contactphysiolymp,
"content.dark-with-image-below-physiolymp": darkwithimagebelowphysiolymp,
"content.hero-physiolymp": herophysiolymp,
"content.hero": hero,
"content.methods-physiolymp": methodsphysiolymp,
"content.patient-slider-physiolymp": patientsliderphysiolymp,
"content.physiolymp-image-gallery": physiolympimagegallery,
"content.simple-text": simpletext,
"content.text-section-with-image-on-right-physiolymp": textsectionwithimageonrightphysiolymp,
"content.text-section-with-title-on-left-physiolymp": textsectionwithtitleonleftphysiolymp,
"content.white-with-black-text-physiolymp": whitewithblacktextphysiolymp,
}